/* Component Dependencies */
var keywordSearchTemplate = require('templates/keywordSearch.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
  type: 'keywordSearch',
  template: {
    'keywordSearch': keywordSearchTemplate
  }
}));
